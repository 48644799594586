<template>
  <div>
    <v-row>
      <v-col cols="4">
        <app-text-search-field @input="handleSearchInput($event)" />
      </v-col>
      <v-col class="text-right d-md-block d-none" cols="8">
        <v-btn
          class="text-capitalize"
          bottom
          large
          color="primary"
          v-if="$acl.can('employeeCreateEdit')"
          @click="handleStoreEmployee((id = null))"
          >Novo</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <!-- <v-select
          v-model="headers"
          :items="headersItems"
          item-text="text"
          chips
          dense
          outlined
          return-object
          multiple
        ></v-select> -->
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="employees.data"
          :items-per-page="-1"
          :loading="loading"
          :options.sync="options"
          @update:options="handleTableUpdate($event)"
          @click:row="handleStoreEmployee($event.id)"
          hide-default-footer
          disable-sort
          dense
        >
          <template v-slot:[`item.status`]="{ item }">
            <status-chip :value="item.status" />
          </template>
          <template v-slot:[`item.doc`]="{ item }">
            <span v-if="item.person_type == 'fisica'">
              {{ $format.cpf(item.cpf) }}
            </span>
            <span v-if="item.person_type == 'juridica'">
              {{ $format.cnpj(item.cnpj) }}
            </span>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="searchParams.page"
            :length="employees.last_page"
            @input="select($event)"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <StoreEmployeesForm @store="select()" ref="StoreEmployeesForm" />
  </div>
</template>

<script>
import StoreEmployeesForm from "@/components/employees/forms/StoreEmployeesForm.vue";

export default {
  components: { StoreEmployeesForm },

  data: () => ({
    headers: [
      { text: "Código", align: "center", value: "code" },
      { text: "Name", align: "start", value: "name" },
      { text: "CPF", align: "center", value: "doc" },
      { text: "Status", align: "center", value: "status" },
    ],

    options: {},

    loading: false,

    searchParams: {
      page: 1,
      orderBy: "name",
      orderDirection: "asc",
    },

    employees: {
      data: [],
    },
  }),

  created() {
    this.select();
  },

  methods: {
    select(page) {
      this.searchParams.page = page;
      this.$http.index("employee/employee", this.searchParams).then((response) => {
        this.employees = response.employees;
      });
    },

    handleTableUpdate(options) {
      // this.options = options;
      //this.searchParams.page = options.page
      this.searchParams.orderBy = options.sortBy[0];
      this.searchParams.orderDirection = options.sortDesc[0] ? "desc" : "asc";
      this.select(options.page);
    },

    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },

    handleStoreEmployee(id = null) {
      if (this.$acl.can('employeeCreateEdit')) {
      this.$refs.StoreEmployeesForm.open(id);
      }
    },
  },
};
</script>

<style>
</style>